export default function (category) {

	if (!document.querySelector('.c-search') || !category) {
		return false;
	}

	const searchForm = document.querySelector('.c-search__form');
	const searchHiddenInput = searchForm.querySelector('input[type="hidden"][name="content"]');

	if(!searchForm || !searchHiddenInput) {
		return;
	}

	searchHiddenInput.value = category;

}
