import Collapse from '../../js/utilities/collapse/u-collapse.js';
import extend from '../../js/utilities/extend/u-extend.js';
import '../../js/vendor/polyfills/polyfill-nodelist-foreach.js';

export default function (accordions) {
	accordions.forEach((accordion) => {

		if (accordion.dataset.initiated === 'true') {
			return;
		}

		accordion.dataset.initiated = 'true';

		let settings = {
			element: accordion,
			expanded: accordion.dataset.expanded === 'true',
			selectors: {
				header: '.c-accordion__trigger',
				content: '.c-accordion__content'
			},
			classNames: {
				expanded: 'c-accordion--is-expanded',
				collapsed: 'c-accordion--is-collapsed',
				button: 'c-accordion__button o-click-area',
				iconExtraClass: 'c-accordion__icon'
			},
			strings: {
				show: 'View details',
				hide: 'Hide details'
			},
			structuralStrings: false,
			icons: {
				collapsed: '/gui/project/images/icons/caret-down.svg',
				expanded: '/gui/project/images/icons/caret-up.svg'
			}
		};

		if (accordion.getAttribute('data-js-accordion-settings')) {
			settings = extend(settings, JSON.parse(accordion.getAttribute('data-js-accordion-settings')));
		}

		new Collapse(settings);

	});
}
