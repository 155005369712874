/* global translations */

/**
 * Get the translation for a string
 *
 * @param {String} path The path used to locate the translation in the translations object
 * @param {Array} variables An array of variables that will replace placeholders with the matching zero-based index in the translation string, eg. 'This string {0} two {1}'
 * @returns {String} The translated string if found, or else the supplied path as a fallback
 */
export default function (path, variables = []) {
	let found = typeof translations === 'undefined' ? {} : translations;

	function lostInTranslation () {
		found = '[' + path + ']';
		let message = `Missing translation for: [${path}]`;
		if (typeof translations === 'undefined') {
			message = message + '. The translations object is undefined.';
		}
		console.warn(message);
	}

	try {
		const parts = path.split('.');
		let i = 0;

		// Find the translation
		while (parts[i]) {
			found = found[parts[i]];
			i++;
		}

		const placeholderRegExp = /\{(\d+)\}/g;
		let placeholderCount = 0;
		let missingVariableCount = 0;
		let placeholderMatch;

		// Iterate over captures while replacing placeholders with variables, keeping track of
		// any missing variables, and finding out how many placeholders the string contains.
		// See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/exec
		while ((placeholderMatch = placeholderRegExp.exec(found)) !== null) {
			placeholderCount++;
			let placeholderIndex = placeholderMatch[1];

			if (variables[placeholderIndex]) {
				found = found.replace(`{${placeholderIndex}}`, variables[placeholderIndex]);
			}
			else {
				missingVariableCount++;
			}
		}

		// Warn if variable placeholders don’t match the supplied variables
		if (missingVariableCount || placeholderCount !== variables.length) {
			console.warn(`The number and/or index of the variable placeholders in ${path} don’t match the number of supplied variables`);
		}
	}
	catch (e) {
		lostInTranslation();
	}

	if (!found) {
		lostInTranslation();
	}

	return found;
}
