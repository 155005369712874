// Import a polyfill for node.remove() (IE)
import '../../vendor/polyfills/polyfill-remove.js';

import Component from '../../core/component.js';
import generateRandomId from '../random-id/u-random-id.js';
import icon from '../../../components/icon/c-icon.js';

class Collapse extends Component {
	/**
	 * Default settings for this component
	 * @return {object} Default settings
	 */
	_defaultSettings () {
		return {
			debug: false,
			element: null,
			expanded: false,
			icons: null, // URLstring or {collapsed: URLstring, expanded: URLstring}
			selectors: {
				header: '> h3',
				content: '> div'
			},
			classNames: {
				expanded: 'is-expanded',
				collapsed: 'is-collapsed',
				button: ''
			},
			strings: {
				show: 'Show content',
				hide: 'Hide content'
			},
			structuralStrings: false,
			structuralClass: 't-visually-hidden',
			on: {
				beforeInit:     () => {},
				beforeToggle:   () => {},
				beforeExpand:   () => {},
				beforeCollapse: () => {},
				init:           () => {},
				toggle:         () => {},
				expand:         () => {},
				collapse:       () => {}
			},
			prepareCssAnimation: false
		};
	}

	/**
	 * Initialize Collapse
	 */
	_init () {
		// Run beforeInit hook
		this.settings.on.beforeInit();

		// Assign main element
		this.element = this.settings.element;

		// Generate element ID if no ID is set
		if (!this.element.id) {
			generateRandomId(this.element, false, 'collapse-');
		}

		// Fetch header element from the DOM if any
		if (document.querySelector(`#${this.element.id} ${this.settings.selectors.header}`)) {
			this.header = document.querySelector(`#${this.element.id} ${this.settings.selectors.header}`);
			this.headerElement = true;
		}
		else {
			this.header = document.createElement('div');
			this.headerElement = false;
		}
		// Fetch content element from the DOM
		this.content = document.querySelector(`#${this.element.id} ${this.settings.selectors.content}`);

		// Set element ID for content element if no ID is set
		if (!this.content.id) {
			this.content.id = `${this.element.id}-content`;
		}

		// Create toggle button
		this.button = document.createElement('button');
		this.button.className = this.settings.classNames.button;
		this.button.setAttribute('type', 'button');
		this.button.setAttribute('aria-expanded', true);
		this.button.setAttribute('aria-controls', this.content.id);
		this.button.innerHTML = this.header.innerHTML;

		// Add on click event to toggle button
		this.button.addEventListener('click', this.toggle.bind(this));

		// Set css animation prepared to false as default
		this.cssAnimationIsPrepared = false;

		// Set class and aria expanded as collapsed if the content is collapsed as default
		if (this.settings.expanded === false) {
			this.element.classList.add(this.settings.classNames.collapsed);
			this.button.setAttribute('aria-expanded',  false);

			// COMMENT
			if (this.settings.prepareCssAnimation) {
				this.content.style.height = '0px';
			}
		}
		// Set class as expanded
		else {
			this.element.classList.add(this.settings.classNames.expanded);
		}

		if (this.settings.strings.hide && this.settings.strings.show) {
			// Add hidden show/hide text for the toggle button
			this.buttonAction = document.createElement('span');

			if (this.settings.structuralStrings && this.settings.structuralClass) {
				this.buttonAction.classList.add(this.settings.structuralClass);
			}

			// Add show/hide text to toggle button
			this.buttonAction.innerHTML = (this.isExpanded()) ? this.settings.strings.hide : this.settings.strings.show;
			this.button.appendChild(this.buttonAction);
		}

		// Replace header with the new toggle button
		this.header.innerHTML = '';
		if (this.headerElement) {
			this.header.appendChild(this.button);
		}
		else {
			this.element.insertBefore(this.button, this.element.firstChild);
		}

		// Init button icon
		this._initIcon();
		this._updateIcon();

		// Run init hook
		this.settings.on.init(this);
	}

	/**
	 * Initialize icon URL
	 */
	_initIcon () {
		// If icons setting is a string, then use the string
		if (typeof this.settings.icons === 'string') {
			this.iconURL = this.settings.icons;
		}
		// Else if the icons setting properties collapsed/expanded is set, then use them
		else if (this.settings.icons.collapsed && this.settings.icons.expanded) {
			this.iconURL = {
				collapsed: this.settings.icons.collapsed,
				expanded: this.settings.icons.expanded
			};
		}
		// Otherwise set the icon URL to false
		else {
			this.iconURL = false;
		}
	}

	/**
	 * Get button icon
	 * @return {mixed} [Icon element]
	 */
	_getIcon () {
		// Return false if we don't want icons
		if (!this.iconURL) {
			return false;
		}

		// Get current state
		let state = (this.isExpanded()) ? 'expanded' : 'collapsed';

		// If we allways use the same icon
		if (typeof this.iconURL === 'string') {
			// Return icon element
			return icon({
				icon: this.iconURL
			});
		}
		// If we want different icons depending on the state
		else if (this.iconURL[state]) {
			// Return icon element for current state
			return icon({
				icon: this.iconURL[state]
			});
		}

		return false;
	}

	_updateIcon () {
		// Check if we need to update the icon, if not exit
		if (!this.iconURL || (this.icon && typeof this.iconURL === 'string')) {
			return;
		}

		// If there is a icon, remove it
		if (this.icon) {
			this.icon.remove();
		}

		// Get the new icon
		this.icon = this._getIcon();

		// Append the icon to toggle button
		if (this.icon) {
			this.button.appendChild(this.icon);
		}
	}

	/**
	 * Prepare content for CSS animations
	 */
	_prepareCssAnimationCollapse () {
		// Exit if the content is already collapsed
		if (this.isCollapsed()) {
			return;
		}

		// Get the content height
		const sectionHeight = this.content.scrollHeight;

		// Temporarily disable CSS animations
		let elementTransition = this.content.style.transition;
		this.content.style.transition = '';

		/* On the next frame (as soon as the previous style change has taken effect),
		 * explicitly set the element's height to its current pixel height, so we
		 * aren't transitioning out of 'auto' */
		requestAnimationFrame(() => {
			this.content.style.height = sectionHeight + 'px';
			this.content.style.transition = elementTransition;

			/* On the next frame (as soon as the previous style change has taken effect),
			 * have the element transition to height: 0 */
			requestAnimationFrame(() => {
				this.content.style.height = 0 + 'px';
			});
		});
	}

	/**
	 * Prepare content for CSS animations
	 */
	_prepareCssAnimationExpand () {
		// Exit if the content is already expanded
		if (this.isExpanded()) {
			return;
		}

		let self = this;

		// Get the content height
		const sectionHeight = this.content.scrollHeight;

		// Set content to it's full height to let CSS animate the height
		this.content.style.height = sectionHeight + 'px';

		// If we already have prepared css animation, then exit
		if (this.cssAnimationIsPrepared) {
			return;
		}

		// Set css animtaion to prepared
		this.cssAnimationIsPrepared = true;

		// Reset style.height when the animation is completed
		this.content.addEventListener('transitionend', function () {
			// Make sure we only do this then the content is expanded
			if (self.isExpanded()) {
				this.style.height = null;
			}
		});
	}

	/* =============================================================================
	 * Public methods
	============================================================================= */

	/**
	 * Toggle content
	 */
	toggle () {
		// Run beforeToggle hook
		this.settings.on.beforeToggle(this);

		// If the content is expanded, then collpase the content
		if (this.button.getAttribute('aria-expanded') === 'true') {
			this.collapse();
		}
		// Otherwise expand the content
		else {
			this.expand();
		}

		// Run toggle hook
		this.settings.on.toggle(this);
	}

	/**
	 * Expand content
	 */
	expand () {
		// Run beforeExpand hook
		this.settings.on.beforeExpand(this);

		// If we want to prepare CSS animation of the content height
		if (this.settings.prepareCssAnimation) {
			this._prepareCssAnimationExpand();
		}

		// Set aria-expanded to true
		this.button.setAttribute('aria-expanded', true);

		// Toggle collapse/expanded classes
		this.element.classList.remove(this.settings.classNames.collapsed);
		this.element.classList.add(this.settings.classNames.expanded);

		// Uppdate toggle button hidden text
		this.buttonAction.innerHTML = this.settings.strings.hide;

		// Update toggle button icon
		this._updateIcon();

		// Run expand hook
		this.settings.on.expand(this);
	}

	/**
	 * Collapse content
	 */
	collapse () {
		// Run beforeCollapse hook
		this.settings.on.beforeCollapse(this);

		// If we want to prepare CSS animation of the content height
		if (this.settings.prepareCssAnimation) {
			this._prepareCssAnimationCollapse();
		}

		// Set aria-expanded to false
		this.button.setAttribute('aria-expanded', false);

		// Toggle collapse/expanded classes
		this.element.classList.add(this.settings.classNames.collapsed);
		this.element.classList.remove(this.settings.classNames.expanded);

		// Uppdate toggle button hidden text
		this.buttonAction.innerHTML = this.settings.strings.show;

		// Update toggle button icon
		this._updateIcon();

		// Run collapse hook
		this.settings.on.collapse(this);
	}

	/**
	 * Checkes if content is expanded
	 * @return {Boolean}
	 */
	isExpanded () {
		return this.button.getAttribute('aria-expanded') === 'true';
	}

	/**
	 * Checkes if content is collapsed
	 * @return {Boolean}
	 */
	isCollapsed () {
		return this.button.getAttribute('aria-expanded') !== 'true';
	}
}

export default Collapse;
