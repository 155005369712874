// Import polyfills (remove if all JS-supported browsers have native support)
import '../../js/vendor/polyfills/polyfill-nodelist-foreach.js';
import '../../js/vendor/polyfills/polyfill-remove.js';

// Import dependencies
import extend from '../../js/utilities/extend/u-extend.js';
import throttle from '../../js/utilities/throttle/u-throttle.js';

// An array to store all responsive tables so we can check if they have been
// initialised already. Once browser support is there this should be a static
// property of the class.
const _responsiveTables = [];

/**
 * Class that makes a table responsive by transforming it when necessary.
 * @requires throttle
 */
class ResponsiveTable {

	/**
	 * Constructor, merges settings and sets up event handlers.
	 * @param {object} [settings] Configuration object.
	 * @param {Node} element - A DOM node representing the table element that will be made responsive.
	 * @param {string} [settings.classNamePrefix=c-responsive-table] - A prefix used for inserted class names.
	 */
	constructor(settings = {}) {
		// Default settings
		this.defaults = {
			element: null,
			classNamePrefix: 'c-responsive-table'
		};

		// Merge settings
		this.settings = extend(this.defaults, settings);

		// Create a shortcut to the table element
		this.table = this.settings.element;

		// A property to save the table’s width before it was transformed in
		this.breakWidth = null;

		// If this.table exists, is a table element, and hasn’t already been
		// initialised, add the table element to the storage array, save the
		// value of its `data-breakpoint` attribute, if any, and add event
		// handlers to trigger transformation. Note the arrow function in the
		// call to `throttle()` to scope `this`.
		if (this.table && this.table.nodeName.toLowerCase() === 'table' && _responsiveTables.indexOf(this.table) === -1) {
			_responsiveTables.push(this.table);
			this.mq = this.table.getAttribute('data-breakpoint');
			['newcontent', 'load', 'resize', 'orientationchange'].forEach(e => {
				window.addEventListener(e, throttle(() => this._updateTable(), 300));
			});
		}
	}

	/**
	 * Add roles to the table element to preserve semantics removed by changing
	 * display properties via CSS.
	 * @private
	 */
	_addRoles() {
		this.table.setAttribute('role', 'table');
		this.table.querySelectorAll('thead, tbody, tfoot').forEach(element => element.setAttribute('role', 'rowgroup'));
		this.table.querySelectorAll('tr').forEach(element => element.setAttribute('role', 'row'));
		this.table.querySelectorAll('td').forEach(element => element.setAttribute('role', 'cell'));
		this.table.querySelectorAll('th').forEach(element => element.setAttribute('role', 'columnheader'));
		this.table.querySelectorAll('th[scope="row"]').forEach(element => element.setAttribute('role', 'rowheader'));
	}

	/**
	 * Remove roles added by _addRoles.
	 * @private
	 */
	_removeRoles() {
		this.table.removeAttribute('role');
		this.table.querySelectorAll('thead, tbody, tfoot, tr, th, td').forEach(element => element.removeAttribute('role'));
	}

	/**
	 * Clone header cells that span multiple rows to give each row a header.
	 * For simplicity this only looks at the first header cell in each row.
	 * The value of the `rowspan` attribute of the original header cell is
	 * temporarily stored in a `data-rowspan` attribute so we can restore it.
	 * @private
	 */
	_cloneHeaderCells() {
		const rows = this.table.querySelectorAll('tbody tr');
		rows.forEach((tr, index) => {
			const rowspans = tr.querySelectorAll('th[rowspan]:first-child');
			rowspans.forEach(cell => {
				const rowspan = parseInt(cell.getAttribute('rowspan'), 10);
				if (rowspan > 1) {
					for (let i = 1; i < rowspan; i++) {
						const nextRow = rows[index + i];
						if (nextRow) {
							const newCell = cell.cloneNode(true);
							newCell.removeAttribute('rowspan');
							newCell.classList.add(`${this.settings.classNamePrefix}__cloned-cell`);
							nextRow.insertBefore(newCell, nextRow.firstChild);
						}
					}
					cell.setAttribute('data-rowspan', cell.getAttribute('rowspan'));
					cell.removeAttribute('rowspan');
				}
			});
		});
	}

	/**
	 * Remove cloned header cells and restore rowspan attributes on the
	 * original header cells.
	 * @private
	 */
	_restoreHeaderCells() {
		this.table.querySelectorAll(`.${this.settings.classNamePrefix}__cloned-cell`).forEach(cell => cell.remove());
		this.table.querySelectorAll('[data-rowspan]').forEach(cell => {
			cell.setAttribute('rowspan', cell.getAttribute('data-rowspan'));
			cell.removeAttribute('data-rowspan');
		});
	}

	/**
	 * Insert elements that clone the contents of the column headers.
	 * The default behaviour is to use `innerHTML` to copy the entire contents
	 * of each column header cell. If this causes problems, you can use a
	 * `data-header` attribute on the column headers to specify a string to use
	 * instead.
	 * This function takes `colspan` attributes on data cells into account,
	 * i.e. data cells can span multiple columns.
	 * @private
	 */
	_insertFakeHeaders() {
		const columnHeaders = this.table.querySelectorAll('thead tr:first-child th');
		this.table.querySelectorAll('tbody tr').forEach(tr => {
			const cells = tr.querySelectorAll('th, td');
			let columnOffset = 0;
			cells.forEach(cell => {
				const header = columnHeaders[Array.prototype.indexOf.call(cells, cell) + columnOffset];
				const fakeHeader = document.createElement('span');
				fakeHeader.classList.add(`${this.settings.classNamePrefix}__fake-header`);
				fakeHeader.innerHTML = header.getAttribute('data-header') ? header.getAttribute('data-header') : header.innerHTML;
				cell.insertBefore(fakeHeader, cell.firstChild);
				columnOffset += cell.getAttribute('colspan') ? parseInt(cell.getAttribute('colspan'), 10) - 1 : 0;
			});
		});
	}

	/**
	 * Remove elements inserted by _insertFakeHeaders.
	 * @private
	 */
	_removeFakeHeaders() {
		this.table.querySelectorAll(`.${this.settings.classNamePrefix}__fake-header`).forEach(element => element.remove());
	}

	/**
	 * Check widths and transform or restore the table.
	 * The default is to check if the table is wider than its parent. The
	 * clientWidth of the table at the time it becomes wider than its parent
	 * is saved so we can compare it to the parent’s width. If we don’t, the
	 * transformed table’s width will be used instead, which we don’t want.
	 *
	 * Other breakpoints can be specified via a `data-breakpoint` attribute on
	 * the table element. The value of this, if present, is passed to
	 * window.matchMedia(query). If the media query matches, the table is
	 * transformed. If it doesn’t, the table is restored.
	 * @private
	 */
	_updateTable() {
		const breakWidth = this.breakWidth ? this.breakWidth : this.table.clientWidth;
		if (this.mq && window.matchMedia(this.mq).matches || !this.mq && breakWidth > this.table.parentNode.clientWidth) {
			if (!this.table.classList.contains(`${this.settings.classNamePrefix}--transformed`)) {
				this.breakWidth = this.table.clientWidth; // Save the table’s width when the breakpoint was met
				this._addRoles();
				this._cloneHeaderCells();
				this._insertFakeHeaders();
				this.table.classList.add(`${this.settings.classNamePrefix}--transformed`);
			}
		}
		else if (this.table.classList.contains(`${this.settings.classNamePrefix}--transformed`)) {
			this.breakWidth = null;
			this._removeRoles();
			this._restoreHeaderCells();
			this._removeFakeHeaders();
			this.table.classList.remove(`${this.settings.classNamePrefix}--transformed`);
		}

	}

}

export default ResponsiveTable;
