import '../../js/vendor/polyfills/polyfill-form-data.js';
import '../../js/vendor/polyfills/polyfill-custom-event.js';
import ajax from '../../js/utilities/ajax/u-ajax.js';
import serialize from '../../js/utilities/serialize/u-serialize.js';
import translate from '../../js/utilities/translate/u-translate.js';
import icon from '../icon/c-icon.js';
import Spinner from '../spinner/c-spinner.js';
import accordion from '../accordion/c-accordion.js';

export default function (quickSearch = document.querySelector('.c-quick-search')) {

	if(!quickSearch) {
		return;
	}

	if(!document.querySelector('#' + quickSearch.getAttribute('data-search-id'))) {
		return;
	}

	const destination = quickSearch.querySelector('.c-quick-search__destination');
	const inputSearch = document.querySelector('#' + quickSearch.getAttribute('data-search-id'));
	const inputSearchForm = inputSearch.parentNode;
	const showResultsDelay = 500;

	// Get users motion preferences
	const motionQuery = matchMedia('(prefers-reduced-motion)');

	let timer;

	destination.setAttribute('aria-live', 'polite');

	const closeButton = document.createElement('button');
	closeButton.setAttribute('type', 'button');
	closeButton.classList.add('c-quick-search__close');
	closeButton.classList.add('o-click-area');
	closeButton.innerHTML = '<span class="t-visually-hidden">' + translate('search.close') + '</span>';
	const closeButtonIcon = icon({
		icon: '/gui/project/images/icons/close.svg',
		size: 's'
	});
	closeButton.appendChild(closeButtonIcon);
	closeButton.addEventListener('click', slideOut);

	inputSearch.addEventListener('input', inputUpdate);

	function inputUpdate () {
		clearTimeout(timer);
		timer = setTimeout(() => {
			if(inputSearch.value.length >= 3) {
				search();
			}
			else {
				slideOut();
			}
		}, showResultsDelay);
	}

	function search() {

		const formData = new FormData(inputSearchForm);

		formData.append('quick-search', 'true');

		// Insert a spinner if loading the content takes more than 500 ms
		const spinner = new Spinner();
		const displaySpinner = setTimeout(() => {
			destination.appendChild(spinner.spinner);
		}, 500);

		ajax({
			url: inputSearchForm.getAttribute('action'),
			method: 'GET',
			data: formData,
			onComplete: () => {
				// Remove the spinner if it exists
				clearTimeout(displaySpinner);
				if (destination.querySelector('.c-spinner')) {
					spinner.remove();
				}
			},
			onSuccess: (response) => {

				// Get new content
				let newContent = document.createElement('div');
				newContent.classList.add('c-quick-search__new-content');
				newContent.innerHTML = response;

				// Get number of hits
				const hits = newContent.querySelector('[data-number-of-hits]') ? newContent.querySelector('[data-number-of-hits]').getAttribute('data-number-of-hits') : '0';

				// Create search hits text
				const hitsText = document.createElement('p');
				hitsText.classList.add('c-quick-search__hits');
				hitsText.classList.add('h3');
				hitsText.classList.add('t-text-align-center');
				hitsText.innerHTML = translate('search.hits', [hits, formData.get('q')]);
				newContent.insertBefore(hitsText, newContent.firstChild);

				// Create 'View all results' link
				const searchPageLink = document.createElement('a');
				searchPageLink.setAttribute('href', inputSearchForm.getAttribute('action') + '?' +  serialize(formData));
				searchPageLink.classList.add('c-button');
				if (hits > 0) {
					searchPageLink.innerHTML = translate('search.viewAll', [hits]);
				}
				else {
					searchPageLink.innerHTML = translate('search.allCategories');
				}

				const searchPageLinkWrapper = document.createElement('p');
				searchPageLinkWrapper.appendChild(searchPageLink);
				searchPageLinkWrapper.classList.add('c-quick-search__view-all');

				newContent.appendChild(searchPageLinkWrapper);

				// Check if any content is already showing (used for animations)
				const firstInit = destination.querySelector('.c-quick-search__new-content') ? false : true;

				// Show the new content
				destination.innerHTML = newContent.outerHTML;
				newContent = destination.querySelector('.c-quick-search__new-content');

				newContent.insertBefore(closeButton, newContent.firstChild);

				// Init scripts for new content
				document.dispatchEvent(new CustomEvent('newcontent', {
					detail: {
						context: newContent
					}
				}));

				// Init accordion
				accordion(document.querySelectorAll('[data-module="accordion"]'));

				// Animations
				if (firstInit) {
					// First init: slide in
					newContent.classList.add('b-animation-slide-in');
					newContent.addEventListener('webkitAnimationEnd', (e) => {
						e.target.classList.remove('b-animation-slide-in');
					});
					newContent.addEventListener('animationend', (e) => {
						e.target.classList.remove('b-animation-slide-in');
					});

				}
				else {
					// Content is updated: fade in
					newContent.classList.add('b-animation-fade-in');
					newContent.addEventListener('webkitAnimationEnd', (e) => {
						e.target.classList.remove('b-animation-fade-in');
					});
					newContent.addEventListener('animationend', (e) => {
						e.target.classList.remove('b-animation-fade-in');
					});
				}

			},
			onError: (error) => {
				const errorMsg= document.createElement('p');
				errorMsg.classList.add('t-text-align-center');
				errorMsg.innerHTML = translate('search.error') + ' (' + error + ')';
				destination.appendChild(errorMsg);
			}
		});
	}

	function slideOut() {
		if (!motionQuery.matches && destination.firstChild) {
			destination.firstChild.classList.add('b-animation-slide-out');
			destination.firstChild.addEventListener('webkitAnimationEnd', removeResults);
			destination.firstChild.addEventListener('animationend', removeResults);
		}
		else {
			removeResults();
		}
	}

	function removeResults() {
		if(document.querySelector('.c-quick-search__new-content')) {
			document.querySelector('.c-quick-search__new-content').remove();
			inputSearch.focus();
		}
	}

}
