/**
 * @file This is the main project file where all other scripts should be initiated.
 */

// Import dependencies
import './js/vendor/polyfills/polyfill-custom-event.js';
import initFilters from './components/filter/c-filter.js';
import initTabs from './components/tabs/c-tabs.js';
import amount from './components/form-elements/amount/c-amount.js';
import quickSearch from './components/quick-search/c-quick-search.js';
import accordion from './components/accordion/c-accordion.js';
import ResponsiveTable from './components/responsive-table/c-responsive-table.js';
import expandArea from './components/expand-area/c-expand-area.js';

// Initialise scripts when the DOM is ready
document.addEventListener('DOMContentLoaded', () => {

	// Init tabs
	initTabs(document.querySelectorAll('.c-tabs'));

	// Init Quick search
	quickSearch();

	// Init accordions
	accordion(document.querySelectorAll('[data-module="accordion"]'));

	// Init resposive tables
	document.querySelectorAll('.c-responsive-table').forEach(table => new ResponsiveTable({
		element: table
	}));

	// Init expand-areas
	expandArea(document.querySelectorAll('.c-expand-area'));

	// Init scripts for dynamic created content
	document.addEventListener('newcontent', (e) => {
		let context = e.detail != null ? e.detail.context : e.target;

		// Init filters
		initFilters(context.querySelectorAll('.c-filter'));

		// Init amount
		amount(context.querySelectorAll('.c-amount'));
	});

	document.dispatchEvent(new CustomEvent('newcontent'));

});
