import extend from '../../js/utilities/extend/u-extend.js';
import '../../js/vendor/polyfills/polyfill-nodelist-foreach.js';

export default function (expandAreas) {

	if(!expandAreas) {
		return;
	}

	expandAreas.forEach((expandArea) => {

		let settings = {
			element: expandArea,
			areaClass: 'c-expand-area__area',
			areaCollapsedClass: 'c-expand-area__area--collapsed',
			areaExpandedClass: 'c-expand-area__area--expanded',
			buttonClasses: 'c-expand-area__expand c-button',
			buttonExpandText: 'Expand section',
			buttonCollapseText: 'Collapse section',
		};

		if (expandArea.getAttribute('data-js-expand-area-settings')) {
			settings = extend(settings, JSON.parse(expandArea.getAttribute('data-js-expand-area-settings')));
		}

		// Area
		const area = expandArea.querySelector('.' + settings.areaClass);
		const areaId = 'expand-area-' + Date.now();
		area.classList.add(settings.areaCollapsedClass);

		// Button
		const button = document.createElement('button');
		button.setAttribute('type', 'button');
		button.setAttribute('aria-controls', areaId);
		button.setAttribute('aria-expanded', false);
		button.innerHTML = settings.buttonExpandText;

		// Button classes
		const buttonClasses = settings.buttonClasses.split(' ');
		buttonClasses.forEach((buttonClass) => {
			button.classList.add(buttonClass);
		});

		// Button click
		button.addEventListener('click', () => {
			// If collapsed
			if (area.classList.contains(settings.areaCollapsedClass)) {
				area.classList.add(settings.areaExpandedClass);
				area.classList.remove(settings.areaCollapsedClass);
				button.setAttribute('aria-expanded', true);
				button.innerHTML = settings.buttonCollapseText;
			}
			// If expanded
			else {
				area.classList.add(settings.areaCollapsedClass);
				area.classList.remove(settings.areaExpandedClass);
				button.setAttribute('aria-expanded', false);
				button.innerHTML = settings.buttonExpandText;
			}
		});

		// Insert button
		area.insertBefore(button, area.firstChild);

	});

}
