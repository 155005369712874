import '../../../js/vendor/polyfills/polyfill-nodelist-foreach.js';
import translate from '../../../js/utilities/translate/u-translate.js';
import icon from '../../icon/c-icon.js';

export default function (amounts = document.querySelectorAll('.c-amount')) {

	if(!amounts.length) {
		return;
	}

	function createButton(change, direction) {

		var button = document.createElement('button');
		button.setAttribute('type', 'button');
		button.classList.add('c-amount__button');

		button.innerHTML = '<span class="t-visually-hidden">' + translate('amount.' + change) + '</span>';

		const buttonIcon = icon({
			icon: '/gui/project/images/icons/caret-' + direction + '.svg'
		});
		button.appendChild(buttonIcon);

		return button;
	}

	amounts.forEach((amount) => {

		amount.classList.add('c-amount--enabled');

		const input = amount.querySelector('.c-amount__input');

		const decreaseBtn = createButton('decrease', 'left');
		const increaseBtn = createButton('increase', 'right');

		decreaseBtn.addEventListener('click', () => {
			if(input.value > input.getAttribute('min')) {
				input.value = parseInt(input.value) - 1;
			}
			if(!amount.hasAttribute('aria-live')) {
				amount.setAttribute('aria-live', 'polite');
			}
		});

		increaseBtn.addEventListener('click', () => {
			input.value = parseInt(input.value) + 1;
			if(!amount.hasAttribute('aria-live')) {
				amount.setAttribute('aria-live', 'polite');
			}
		});

		amount.insertBefore(decreaseBtn, amount.firstChild);
		amount.appendChild(increaseBtn);

	});
}
