import Component from '../../js/core/component.js';
import extend from '../../js/utilities/extend/u-extend.js';
import generateRandomId from '../../js/utilities/random-id/u-random-id.js';
import ajax from '../../js/utilities/ajax/u-ajax.js';
import Spinner from '../spinner/c-spinner.js';
import searchCategory from '../search/c-search.js';

// Import a polyfill for nodeList.forEach, eg. document.querySelectorAll('.foo').forEach()
import '../../js/vendor/polyfills/polyfill-nodelist-foreach.js';

// Import a polyfill for ChildNode.remove()
import '../../js/vendor/polyfills/polyfill-remove.js';

/**
 * Tabs Class
 */
class Tabs extends Component {

	/**
	 * Default settings for this component
	 * @return {object} Default settings
	 */
	_defaultSettings () {
		return {
			element: null, // The element that contains the elements that will be turned into tabpanels
			hideTitle: 'structural', // True / false or 'structural'. By default the element containing the title of each tabpanel is hidden
			updateURL: true, // If true, switching tabs will update the URL
			enabledTabsClass: 'c-tabs--enabled', // A class name added to enabled tabs components
			loadOnDemandClass: 'c-tabs--load-on-demand', // A class name that enables loading of tabpanel content via xhr as they are displayed
			tabpanelSelector: '.c-tabs__tabpanel', // Selector for a tabpanel
			tabpanelTitleSelector: '.c-tabs__tabpanel-title', // Selector for the heading inside a tabpanel
			tabpanelContentClass: 'c-tabs__tabpanel-content', // Class name used for the content-wrapping element inside a tabpanel. Required when load-on-demand is enabled, optional otherwise.
			activeTabpanelSelector: '.c-tabs__tabpanel--is-displayed', // Selector for the initially displayed tabpanel. If no tabpanel matches this, the first tabpanel will be displayed.
			tabListClass: 'c-tabs__tablist', // A class name added to the tablist
			tabListClassVariant: 'c-tabs__tablist--carousel', // A class name added to the tablist
			tabListItemClass: 'c-tabs__tablist-item', // A class name added to each tablist item
			tabListTriggerClass: 'c-tabs__tablist-trigger' // A class name added to the triggering element in each tablist item
		};
	}

	/**
	 * Check if we should actually create the tabs
	 */
	_prerequisitesMet () {

		// Don't run script for this element
		if (this.element.classList.contains('.c-tabs--no-js')) {
			return false;
		}

		// This element already has tabs
		if ((this.element.matches || this.element.msMatchesSelector).call(this.element, '.' + this.settings.enabledTabsClass)) {
			return false;
		}

		// Find and store the tabpanel elements
		this.tabpanelElements = this.element.querySelectorAll(this.settings.tabpanelSelector);

		// This element has no tabpanels
		if (!this.tabpanelElements) {
			return false;
		}

		// Check that each panel has a title
		this.tabpanelElements.forEach(tabpanel => {
			if (!tabpanel.querySelector(this.settings.tabpanelTitleSelector)) {
				return false;
			}
		});

		// If load-on-demand is enabled, a link list is required
		if ((this.element.matches || this.element.msMatchesSelector).call(this.element, '.' + this.settings.loadOnDemandClass)) {
			if (!this.element.querySelector('.' + this.settings.tabListClass)) {
				return false;
			}
			this.loadOnDemand = true;
		}

		// All checks passed
		return true;
	}

	/**
	 * Initialize Tabs
	 */
	_init () {
		this.element = this.settings.element;
		if (this._prerequisitesMet()) {
			var extraClass;
			// Find and store the link list if there is one
			this.linkList = this.element.querySelector('.' + this.settings.tabListClass);

			if (this.element.getAttribute('data-carousel')) {
				extraClass = this.settings.tabListClassVariant;
			}
			else {
				if (this.linkList) {
					if (this.linkList.classList.contains('c-tabs__tablist--carousel')) {
						extraClass = this.settings.tabListClassVariant;
					}
					else {
						extraClass = 'c-tabs__tablist--normal';
					}
				}
				else {
					extraClass = 'c-tabs__tablist--normal';
				}
			}

			// All good–go ahead and create the tabs
			this._create(extraClass);

			// Select tab based on URL param
			this._initSelectTab();
		}
	}

	/**
	 * Modify the DOM and set up events
	 */
	_create (extraClass) {
		let hash = document.location.hash.replace('#', '');
		let tabPanelToDisplay;

		this.tabpanels = [];

		this.tablist = document.createElement('ul');
		this.tablist.classList.add(this.settings.tabListClass);
		this.tablist.classList.add(extraClass);
		this.tablist.setAttribute('role', 'tablist');

		this.tabpanelElements.forEach((tabpanelElement, index) => {
			generateRandomId(tabpanelElement, false, 'tabpanel-');

			// Create an item object
			let tabpanel = {
				tabpanelElement: tabpanelElement,
				id: tabpanelElement.getAttribute('id')
			};

			// Should this be the default selected item?
			if ((hash.length && tabpanel.id === hash) || (!hash.length && (tabpanel.tabpanelElement.matches || tabpanel.tabpanelElement.msMatchesSelector).call(tabpanel.tabpanelElement, this.settings.activeTabpanelSelector)) || index === 0) {
				tabPanelToDisplay = tabpanel;
			}

			// Create a tab that is associated with this tabpanel
			tabpanel.tab = document.createElement('li');
			tabpanel.tab.setAttribute('class', this.settings.tabListItemClass);
			tabpanel.tab.setAttribute('role', 'presentation');

			// Create the element that will display this panel when activated
			tabpanel.trigger = document.createElement('button');
			tabpanel.trigger.setAttribute('type', 'button');
			tabpanel.trigger.setAttribute('class', this.settings.tabListTriggerClass);
			tabpanel.trigger.setAttribute('role', 'tab');
			tabpanel.trigger.setAttribute('aria-controls', tabpanel.id);
			tabpanel.trigger.setAttribute('tabindex', '-1');
			generateRandomId(tabpanel.trigger, true, 'tabpanel-trigger-');

			// Create temporary link content container element
			let linkContent = document.createElement('div');
			if (this.linkList) {
				// If there is a link that refers to this tabpanel via a data-tabpanel attribute, use that
				if (this.linkList.querySelector('a[data-tabpanel="' + tabpanel.id + '"]')) {
					linkContent.innerHTML = this.linkList.querySelector('a[data-tabpanel="' + tabpanel.id + '"]').innerHTML;
				}
				else {
					// Otherwise assume that the link will contain a fragment identifier with this tabpanel’s id
					linkContent.innerHTML = this.linkList.querySelector('a[href$="#' + tabpanel.id + '"]').innerHTML;
				}
			}
			else {
				linkContent.innerHTML = tabpanel.tabpanelElement.querySelector(this.settings.tabpanelTitleSelector).innerHTML;
			}
			tabpanel.trigger.innerHTML = linkContent.innerHTML;

			if (this.loadOnDemand) {
				let href = this.linkList.querySelector('a[data-tabpanel="' + tabpanel.id + '"]').getAttribute('href');
				// href = href.indexOf('&') > 0 ? href.substring(0, href.indexOf('&')) : href;
				tabpanel.trigger.setAttribute('data-href', href);
			}

			// Switch tabpanels when a tab is clicked
			tabpanel.trigger.addEventListener('click', this._tabClickEvent.bind(this, tabpanel));

			// Handle keyboard events
			tabpanel.trigger.addEventListener('keydown', this._tabKeydownEvent.bind(this, tabpanel));

			// Insert the created elements
			tabpanel.tab.appendChild(tabpanel.trigger);
			this.tablist.appendChild(tabpanel.tab);

			// Set up the tabpanel
			tabpanel.tabpanelElement.setAttribute('role', 'tabpanel');
			tabpanel.tabpanelElement.setAttribute('tabindex', '-1');
			tabpanel.tabpanelElement.setAttribute('aria-labelledby', tabpanel.trigger.id);
			let titleElement = tabpanelElement.querySelector(this.settings.tabpanelTitleSelector);
			if (titleElement && this.settings.hideTitle) {
				this.settings.hideTitle === 'structural' ? titleElement.classList.add('t-visually-hidden') : titleElement.remove();
			}

			this.tabpanels.push(tabpanel);
		});

		// Remove the link list if there is one and add the created tablist to the DOM
		if (this.linkList) {
			this.linkList.remove();
		}
		this.element.insertBefore(this.tablist, this.element.firstChild);

		/* Give the enabled tabs element a class to
		 * 1. Allow styling depending on whether it is enabled or not (JS off, script errors, whatever)
		 * 2. Check if this element already has tabs enabled
		 */
		this.element.classList.add(this.settings.enabledTabsClass);

		if (tabPanelToDisplay) {
			// Display a tabpanel but don't set focus to the active tab, e.g. when the page loads
			this.displayTabpanel(tabPanelToDisplay, false);
		}

		// Switch tabs when going back or forth in browser history
		if (this.settings.updateURL) {
			window.addEventListener('popstate', () => {
				let path = window.location.href;
				path = path.substring(path.indexOf(window.location.pathname));
				path = path.indexOf('&') > 0 ? path.substring(0, path.indexOf('&')) : path;
				let triggers = this.tablist.querySelectorAll('[data-href]');

				triggers = Array.from(triggers);
				let prevTrigger = triggers.filter((trigger) => {
					let href = trigger.getAttribute('data-href');
					href = href.indexOf('&') > 0 ? href.substring(0, href.indexOf('&')) : href;
					if (path === href) {
						return trigger;
					}
				});

				if (prevTrigger[0]) {
					prevTrigger[0].click();
				}
			});
		}
	}

	/*
	 * Handle Tab click event
	 */
	_tabClickEvent (tabpanel) {
		// Display the tabpanel
		this.displayTabpanel(tabpanel, true);

		// Replace URL when switching between tabs
		if (this.settings.updateURL && !this.loadOnDemand) {
			const query = new URLSearchParams(window.location.search);
			if (tabpanel.tabpanelElement.getAttribute('id')) {
				query.set('tab', tabpanel.tabpanelElement.getAttribute('id'));
				history.replaceState(null, null, window.location.pathname + '?' + query.toString());
			}
		}

		// If load-on-demand is active, check if the tabpanel has content. If it doesn’t, load the content via xhr.
		if (this.loadOnDemand && !tabpanel.tabpanelElement.querySelector(`.${this.settings.tabpanelContentClass}`)) {
			// Create the content-wrapping element and insert it
			const tabpanelContent = document.createElement('div');
			tabpanelContent.classList.add(this.settings.tabpanelContentClass);
			tabpanel.tabpanelElement.appendChild(tabpanelContent);

			// Insert a spinner if loading the content takes more than 500 ms
			const spinner = new Spinner();
			const displaySpinner = setTimeout(() => {
				tabpanelContent.appendChild(spinner.spinner);
			}, 500);

			// Chrome caches the most recent request even if it is just a partial.
			// Adding 'ajax=true' to make sure the AJAX requests uses a different URL from the full HTML document
			let ajaxUrlSuffix;
			if (tabpanel.trigger.getAttribute('data-href').indexOf('?') === -1) {
				ajaxUrlSuffix = '?ajax=true';
			}
			else {
				ajaxUrlSuffix = '&ajax=true';
			}

			// Load the content
			ajax({
				url: tabpanel.trigger.getAttribute('data-href') + ajaxUrlSuffix,
				headers: {
					'X-Requested-With': 'XMLHttpRequest',
					'Vary': 'Accept'
				},
				onComplete: () => {
					clearTimeout(displaySpinner);
				},
				onSuccess: (response) => {
					// Remove the spinner if it exists
					if (tabpanelContent.querySelector('.c-spinner')) {
						spinner.remove();
					}
					// Inject the HTML from the response
					tabpanelContent.outerHTML = response;

					// Init scripts for tabpanel content
					document.dispatchEvent(new CustomEvent('newcontent', {
						detail: {
							context: tabpanel.tabpanelElement
						}
					}));

				}
			});
		}

		// Update the URL if appropriate
		if (this.settings.updateURL && this.loadOnDemand) {
			let path = window.location.href;
			path = path.substring(path.indexOf(window.location.pathname));
			// let param = path.indexOf('&') > 0 ? path.substring(path.indexOf('&')) : '';
			// path = path.indexOf('&') > 0 ? path.substring(0, path.indexOf('&')) : path;
			if (tabpanel.trigger.getAttribute('data-href') !== path) {
				history.pushState(null, null, tabpanel.trigger.getAttribute('data-href'));
			}
		}
	}

	/*
	 * Handle Tab keyboard events
	 */
	_tabKeydownEvent (tabpanel, e) {
		const tabTrigger = e.target;
		const previous = tabTrigger.parentNode.previousElementSibling || this.tablist.lastChild;
		const next = tabTrigger.parentNode.nextElementSibling || this.tablist.firstChild;
		switch (e.key) {
			case 'Left': // IE and older versions Edge
			case 'ArrowLeft': // Standard
				// Move to the previous or last tab
				previous.querySelector('.' + this.settings.tabListTriggerClass).click();
				break;
			case 'Right': // IE and older versions Edge
			case 'ArrowRight': // Standard
				// Move to the next or first tab
				next.querySelector('.' + this.settings.tabListTriggerClass).click();
				break;
			case 'Home':
				e.preventDefault();
				// Move to the first tab
				this._getFirstTab().click();
				break;
			case 'End':
				e.preventDefault();
				// Move to the last tab
				this._getLastTab().click();
				break;
			case 'Down': // IE and older versions Edge
			case 'ArrowDown': // Standard
				// Move focus to the displayed tabpanel
				e.preventDefault();
				tabpanel.tabpanelElement.focus();
				break;
		}
	}

	_getFirstTab () {
		return this.tablist.firstChild.querySelector('.' + this.settings.tabListTriggerClass);
	}

	_getLastTab () {
		return this.tablist.lastChild.querySelector('.' + this.settings.tabListTriggerClass);
	}

	// Trigger tab on init based on 'tab'-param in URL
	_initSelectTab () {
		if (this.settings.updateURL && !this.loadOnDemand) {
			const query = new URLSearchParams(window.location.search);
			const tabParam = query.get('tab');
			const tab = this.tablist.querySelector('[aria-controls="' + tabParam + '"]');
			if (tab) {
				tab.click();
			}
		}
	}

	/* =============================================================================
	 * Public methods
	============================================================================= */

	/**
	 * Display a tabpanel and hide all others
	 */
	displayTabpanel (tabPanelToDisplay, setFocus = false) {
		this.tabpanels.forEach(tabpanel => {
			if (tabPanelToDisplay === tabpanel) {
				tabpanel.trigger.setAttribute('aria-selected', 'true');
				tabpanel.trigger.setAttribute('tabindex', '0');
				if (setFocus) {
					tabpanel.trigger.focus();
				}
				tabpanel.tabpanelElement.removeAttribute('hidden');
				// Update search field category
				searchCategory(tabpanel.tabpanelElement.getAttribute('data-search-category'));
			}
			else {
				tabpanel.trigger.setAttribute('aria-selected', 'false');
				tabpanel.trigger.setAttribute('tabindex', '-1');
				tabpanel.tabpanelElement.setAttribute('hidden', '');
			}
		});
	}
}

/**
 * Initialisation function for the component
 * @param {NodeList|String} elements [Either a NodeList or a selector string]
 * @param {Object} settings [User settings]
 */
const initTabs = function (elements, settings = {}) {
	if (typeof elements === 'string') {
		elements = document.querySelectorAll(elements);
	}
	for (let tabs of elements) {
		if (typeof tabs.tabs === 'undefined') {
			tabs.tabs = new Tabs(extend({element: tabs}, settings));
		}
	}
};

export {Tabs};
export default initTabs;
