/**
 * Spinner module.
 * @module
 */
// Import dependencies
import extend from '../../js/utilities/extend/u-extend.js';

/**
 * Class that creates a spinner.
 */
class Spinner {

	/**
	 * Constructor, merges settings and creates spinner markup.
	 * @param {object} [settings] Configuration object.
	 * @param {string} [settings.className=c-spinner] - The class name used for the generated element.
	 * @param {string} [settings.style=double-bounce] - A selector matching the top level items in the menu.
	 * @param {!string} [settings.text=Please wait] - A selector matching the link in a top level item. Must not be set to a falsy value.
	 * @param {string} [settings.spinnerGraphics] - An HTML string that will represent any visual graphics. The default is `
<div class="c-spinner__graphics">
	<div class="c-spinner__graphics-part c-spinner__graphics-part--1"></div>
	<div class="c-spinner__graphics-part c-spinner__graphics-part--2"></div>
</div>`. This can be changed to any HTML string you like, or set to the empty string if you don’t need it.
	 * @param {string} [settings.visuallyHiddenClassName=t-visually-hidden] - A class name added to the element containing the text if {@link hideSpinnerText} is set to true.
	 * @param {boolean} [settings.hideSpinnerText=false] - If true will visually hide the spinner text.
	 * @param {string} [settings.doneClassName=t-visually-hidden] - A class name added to the done element, if one is inserted.
	 * @param {string} [settings.doneText=Done] - The text used in the done element. Set to a falsy value if a done element isn’t needed.
	 */
	constructor (settings = {}) {
		// Default settings
		this.defaults = {
			className: 'c-spinner',
			style: 'double-bounce',
			text: 'Please wait',
			spinnerGraphics: `
<div class="c-spinner__graphics">
	<div class="c-spinner__graphics-part c-spinner__graphics-part--1"></div>
	<div class="c-spinner__graphics-part c-spinner__graphics-part--2"></div>
</div>`,
			visuallyHiddenClassName: 't-visually-hidden',
			hideSpinnerText: false,
			doneClassName: 't-visually-hidden',
			doneText: 'Done'
		};

		// Merge settings
		this.settings = extend(this.defaults, settings);

		// Create the spinner container element
		this.spinnerElement = document.createElement('div');
		this.spinnerElement.setAttribute('role', 'alert');
		this.spinnerElement.classList.add(this.settings.className);
		if (this.settings.style) {
			this.spinnerElement.classList.add(`${this.settings.className}--${this.settings.style}`);
		}

		// Insert the graphics markup
		this.spinnerElement.innerHTML = this.settings.spinnerGraphics;

		// Insert the text
		if (!this.settings.text || this.settings.text.trim() === '') {
			throw new Error(`The value of the text parameter must be a non-empty string. The default value is “${this.defaults.text}” and you set it to “${this.settings.text}”.`);
		}
		this.textElement = document.createElement('div');
		this.textElement.classList.add(`${this.settings.className}__text`);
		this.textElement.textContent = this.settings.text;
		if (this.settings.hideSpinnerText) {
			this.textElement.classList.add(this.settings.visuallyHiddenClassName);
		}
		this.spinnerElement.appendChild(this.textElement);
		Spinner._createDoneElement(this);
	}

	/**
	 * Get the Spinner DOM structure.
	 * @return {Node} Spinner DOM structure.
	 */
	get spinner () {
		return this.spinnerElement;
	}

	/**
	 * Append the spinner element to the body element.
	 */
	insert () {
		document.documentElement.appendChild(this.spinnerElement);
	}

	/**
	 * Create a visually hidden element that can be used to notify screen
	 * reader users when the spinner is removed.
	 * This method is static since we only want one of these elements to
	 * exist at any time.
	 * @private
	 */
	static _createDoneElement (spinnerInstance) {
		// Remove the element if it exists
		if (this._doneElement) {
			this._doneElement.parentNode.removeChild(this._doneElement);
			delete this._doneElement;
		}

		// Only create the element if the instance supplies text for “done”
		if (spinnerInstance.settings.doneText) {
			this._doneElement = document.createElement('div');
			this._doneElement.classList.add(spinnerInstance.settings.visuallyHiddenClassName);
			this._doneElement.setAttribute('aria-live', 'polite');
			document.documentElement.appendChild(this._doneElement);
		}
	}

	/**
	 * Update the text of _doneElement.
	 * This method is static since we only want one of these elements to
	 * exist at any time.
	 * @private
	 */
	static _updateDoneElement (spinnerInstance) {
		if (this._doneElement && spinnerInstance.settings.doneText) {
			this._doneElement.textContent = spinnerInstance.settings.doneText;
		}
	}

	/**
	 * Remove the spinner element from the DOM.
	 */
	remove () {
		// Check that this.spinnerElement is actually in the DOM first
		if (this.spinnerElement.parentNode) {
			this.spinnerElement.parentNode.removeChild(this.spinnerElement);
		}
		Spinner._updateDoneElement(this);
	}
}

export default Spinner;
