// Import dependencies
import Component from '../../js/core/component.js';
import extend from '../../js/utilities/extend/u-extend.js';
import '../../js/vendor/polyfills/polyfill-nodelist-foreach.js';
import ajax from '../../js/utilities/ajax/u-ajax.js';
import Spinner from '../spinner/c-spinner.js';

/**
 * Class that […]
 * @extends Component
 */
class Filter extends Component {
	/**
	 * Default settings
	 * @return {object} Default settings
	 */
	_defaultSettings () {
		return {
			element: null,
			filterItemsType: 'checkbox',
			formSelector: '.c-filter__form',
			submitSelector: '.c-filter__submit',
			targetAttribute: 'data-filter-target',
			updateTargetSelector: '.c-search-result__container'
		};
	}

	_prerequisitesMet () {

		this.element = this.settings.element;

		this.target = document.querySelector('#' + this.element.getAttribute(this.settings.targetAttribute));

		this.updateTarget = this.target.querySelector(this.settings.updateTargetSelector);

		if (!this.updateTarget) {
			return false;
		}

		this.filters = this.element.querySelectorAll('input[type="' + this.settings.filterItemsType + '"]');

		if (!this.filters.length) {
			return false;
		}

		this.form = this.element.querySelector(this.settings.formSelector);

		if (!this.form) {
			return false;
		}

		this.submit = this.element.querySelector(this.settings.submitSelector);

		if (!this.submit) {
			return false;
		}

		return true;
	}

	/**
	 * Initialize the component
	 */
	_init () {
		if(!this._prerequisitesMet()) {
			return false;
		}
		this._log('_init()');

		this.filters.forEach((filter) => {
			filter.addEventListener('change', this._submitFilter.bind(this));
		});

		// Hide the filter submit button
		this.submit.style.display = 'none';

	}

	_submitFilter() {

		// Set aria-live to the target element
		if(!this.updateTarget.hasAttribute('aria-live')) {
			this.updateTarget.setAttribute('aria-live', 'polite');
		}

		let formData = new FormData(this.form);

		// Insert a spinner if loading the content takes more than 500 ms
		const spinner = new Spinner();
		const displaySpinner = setTimeout(() => {
			this.target.appendChild(spinner.spinner);
		}, 500);

		ajax({
			url: this.form.getAttribute('action'),
			method: 'GET',
			data: formData,
			onComplete: () => {
				// Remove the spinner if it exists
				clearTimeout(displaySpinner);
				if (this.target.querySelector('.c-spinner')) {
					spinner.remove();
				}
			},
			onSuccess: (response) => {
				// Update with new content
				const newContent = document.createElement('div');
				newContent.innerHTML = response;
				this.updateTarget.innerHTML = newContent.querySelector(this.settings.updateTargetSelector).innerHTML;

				// Init scripts for tabpanel content
				document.dispatchEvent(new CustomEvent('newcontent', {
					detail: {
						context: this.target
					}
				}));
			}
		});
	}

}

/**
 * Initialisation function for the component
 * @param {NodeList|String} elements [Either a NodeList or a selector string]
 * @param {Object} settings [User settings]
 */
const initFilters = function (elements, settings = {}) {
	if (typeof elements === 'string') {
		elements = document.querySelectorAll(elements);
	}
	for (let filters of elements) {
		if (typeof filters.filters === 'undefined') {
			filters.filters = new Filter(extend({element: filters}, settings));
		}
	}
};

export {Filter};
export default initFilters;
